const experience = [
  {
    date: "2018 - Present",
    image: "/images/about/experience/1.jpeg",
    title: "Software Engineer",
    company: "Tech Solutions",
    desc: "Developed and maintained web applications using modern frameworks. Collaborated with cross-functional teams to deliver high-quality software solutions on time and within budget.",
    location: "London, England",
  },
  {
    date: "2010 - 2013",
    image: "/images/about/experience/2.jpeg",
    title: "Backend Developer",
    company: "Innovative Systems",
    desc: "Designed and implemented server-side logic, optimized database performance, and ensured scalability of applications. Worked closely with front-end developers to integrate APIs and deliver seamless user experiences.",
    location: "London, England",
  },
  {
    date: "2009 - 2010",
    image: "/images/about/experience/3.jpeg",
    title: "Full Stack Developer",
    company: "Creative Minds",
    desc: "Led the development of full-stack web applications, managing both front-end and back-end components. Introduced best practices for code quality, testing, and deployment, improving overall project efficiency.",
    location: "London, England",
  },
  {
    date: "2004 - 2007",
    image: "/images/about/experience/4.jpeg",
    title: "Mobile App Developer",
    company: "NextGen Apps",
    desc: "Created and maintained mobile applications for iOS and Android platforms. Focused on user-friendly interfaces and robust functionality, resulting in a 20% increase in user engagement.",
    location: "London, England",
  },
];

export default experience;
