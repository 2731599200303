const helmet = {
  home: {
    title: "Home Page",
    desc: "Home Page Description",
  },
  about: {
    title: "About Us",
    desc: "About Us Description",
  },
  blog: {
    title: "Blog",
    desc: "Blog Description",
  },
  cv: {
    title: "CV",
    desc: "Cv Description",
  },
  category: {
    title: "Category",
    desc: "Category Description",
  },
  cart: {
    title: "Shopping Cart",
    desc: "Shopping Cart Description",
  },
  contact: {
    title: "Contact Us",
    desc: "Contact Us Description",
  },
  error: {
    title: "Error",
    desc: "Error Page Description",
  },
  projects: {
    title: "Projects",
    desc: "Projects Description",
  },
  services: {
    title: "Services",
    desc: "Services Description",
  },
  team: {
    title: "Our Team",
    desc: "Our Team Description",
  },
  workspace: {
    title: "Workspace",
    desc: "Workspace Description",
  },
  stack: {
    title: "Stack",
    desc: "Stack Description",
  },
  mysetup: {
    title: "My Setup",
    desc: "My Setup Description",
  },
  biolink: {
    title: "BioLink",
    desc: "BioLink Description",
  },checkout: {
    title: "Checkout",
    desc: "Checkout Description",
  },
};

export default helmet;
