const about = [
  {
    image: "/images/about/skills/icon.svg",
    text: "Skills",
  },
  {
    image: "/images/about/experience/icon.svg",
    text: "Experience",
  },
  {
    image: "/images/about/education/icon.svg",
    text: "Education",
  },
  {
    image: "/images/about/certifications/icon.svg",
    text: "Certifications",
  },
  {
    image: "/images/about/testimonials/icon.svg",
    text: "Testimonials",
  },
];

export default about;
