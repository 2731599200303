import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const team = [
  {
    image: "/images/team/1.webp",
    name: "Jone Due",
    job: "Sr. Web Developer",
    desc: "Bonnie drives the technical strategy of the flowbite platform and brand.",
    social: [
      { icon: <FaFacebookF />, url: "#" },
      { icon: <GrInstagram />, url: "#" },
      { icon: <FaLinkedinIn />, url: "#" },
      { icon: <FaTwitter />, url: "#" },
    ],
  },
  {
    image: "/images/team/2.webp",
    name: "Jone Due",
    job: "Sr. Web Developer",
    desc: "Bonnie drives the technical strategy of the flowbite platform and brand.",
    social: [
      { icon: <FaFacebookF />, url: "#" },
      { icon: <GrInstagram />, url: "#" },
      { icon: <FaLinkedinIn />, url: "#" },
      { icon: <FaTwitter />, url: "#" },
    ],
  },
  {
    image: "/images/team/3.webp",
    name: "Jone Due",
    job: "Sr. Web Developer",
    desc: "Bonnie drives the technical strategy of the flowbite platform and brand.",
    social: [
      { icon: <FaFacebookF />, url: "#" },
      { icon: <GrInstagram />, url: "#" },
      { icon: <FaLinkedinIn />, url: "#" },
      { icon: <FaTwitter />, url: "#" },
    ],
  },
  {
    image: "/images/team/4.webp",
    name: "Jone Due",
    job: "Sr. Web Developer",
    desc: "Bonnie drives the technical strategy of the flowbite platform and brand.",
    social: [
      { icon: <FaFacebookF />, url: "#" },
      { icon: <GrInstagram />, url: "#" },
      { icon: <FaLinkedinIn />, url: "#" },
      { icon: <FaTwitter />, url: "#" },
    ],
  },
];

export default team;
