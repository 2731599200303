const skills = [
  {
    image: "/images/about/skills/technology/tailwindcss.svg",
  },
  {
    image: "/images/about/skills/technology/bootstrap.svg",
  },
  {
    image: "/images/about/skills/technology/css.svg",
  },
  {
    image: "/images/about/skills/technology/figma.svg",
  },
  {
    image: "/images/about/skills/technology/firebase.svg",
  },
  {
    image: "/images/about/skills/technology/git.svg",
  },
  {
    image: "/images/about/skills/technology/javascript.svg",
  },
  {
    image: "/images/about/skills/technology/mysql.svg",
  },
  {
    image: "/images/about/skills/technology/nodejs.svg",
  },
  {
    image: "/images/about/skills/technology/react.svg",
  },
];

export default skills;
