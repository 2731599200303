import React from "react";
import { FaAmazonPay, FaShoppingBasket } from "react-icons/fa";
import { BiLogoEtsy } from "react-icons/bi";
 
const workspaceData = {
  header: {
    title: "Products I Use",
    desc: "I've listed my favorite products for you",
  },
  activeButton: "amazon",
  type: "workspace",
  buttons: [
    {
      name: "Amazon",
      tag: "amazon",
      desc: "I've been a prime member for a long time on Amazon and I enjoy shopping there. Here are the products I bought from Amazon",
      icon: <FaAmazonPay size={"20px"} />,
    },
    {
      name: "Etsy",
      tag: "etsy",
      desc: "Etsy is a great platform for unique and handmade items. Here are some products I found on Etsy",
      icon: <BiLogoEtsy size={"20px"} />,
    },
    {
      name: "Temu",
      tag: "temu",
      desc: "Temu is a great marketplace with a variety of products. Here are my picks from Temu",
      icon: <FaShoppingBasket size={"20px"} />,
    },
  ],
  items: [
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/1.png",
      name: "Fellow Clyde Electric Kettle",
      price: "$99",
      category: "Living",
      link: "#",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/2.png",
      name: "solo stove Ranger 2.0",
      price: "$199.99",
      category: "Living",
      link: "#",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/3.png",
      name: "Bang & Olufsen Beosound A1 2nd Gen",
      price: "$299",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/4.png",
      name: "Flipper Zero",
      price: "$169",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/5.png",
      name: "Sonos Ace",
      price: "$499",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/6.png",
      name: "teenage engineering Computer-1",
      price: "$99",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/7.png",
      name: "Nothing Watch Pro",
      price: "$69",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/8.png",
      name: "Lofree TOUCH PBT Wireless Mouse",
      price: "$69.99",
      category: "Workspace",
      link: "#",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/9.png",
      name: "Isamu Noguchi Akari 3A",
      price: "$400",
      category: "Living",
      link: "#",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/10.png",
      name: "LEFF amsterdam Tube Alarm Clock",
      price: "$99",
      category: "Living",
      link: "#",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/11.png",
      name: "Tidbyt Tidbyt",
      price: "$179",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/12.png",
      name: "Apple Vision Pro",
      price: "$3499",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/13.png",
      name: "Sonos Era 100",
      price: "$279",
      category: "Tech",
      link: "#",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/14.png",
      name: "teenage engineering Field Desk",
      price: "$1599",
      category: "Workspace",
      link: "#",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/15.png",
      name: "Isamu Noguchi Akari 21A",
      price: "$400",
      category: "Living",
      link: "#",
    },
  ],
};

export default workspaceData;
