import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homestyle: localStorage.getItem("homestyle") || "home1",
};

const homeSlice = createSlice({
  name: "homestyle",
  initialState,
  reducers: {
    homeChange: (state,action) => {
      state.homestyle = action.payload;
      localStorage.setItem("homestyle", action.payload);
    }
  },
});

export const { homeChange } = homeSlice.actions;

export default homeSlice.reducer;
