const testimonials = [
  {
    image: "/images/about/testimonials/1.jpeg",
    name: "John Doe",
    position: "CEO at Company",
    testimonial:
      "The service was amazing. I never had to wait that long for my food. The staff was friendly and attentive, and the delivery was impressively prompt.",
    star: 5,
  },
  {
    image: "/images/about/testimonials/2.jpeg",
    name: "John Doe",
    position: "CEO at Company",
    testimonial:
      "The service was amazing. I never had to wait that long for my food. The staff was friendly and attentive, and the delivery was impressively prompt.",
    star: 5,
  },
  {
    image: "/images/about/testimonials/3.jpeg",
    name: "John Doe",
    position: "CEO at Company",
    testimonial:
      "The service was amazing. I never had to wait that long for my food. The staff was friendly and attentive, and the delivery was impressively prompt.",
    star: 5,
  },
];

export default testimonials;
