const pageTitle = {
  home: {
    title: "Home Page",
    desc: "Home Page Description",
  },
  about: {
    title: "About Us",
    desc: "About Us Description",
  },
  blog: {
    title: "Blog",
    desc: "Blog Description",
  },
  blog1: {
    title: "Blog Card One Style",
    desc: "Blog Card One Style Description",
  },
  blog2: {
    title: "Blog Card Two Style",
    desc: "Blog Card Two Style Description",
  },
  blog3: {
    title: "Blog Card Three Style",
    desc: "Blog Card Three Style Description",
  },
  category: {
    title: "Category",
    desc: "Category Description",
  },
  cart: {
    title: "Shopping Cart",
    desc: "Shopping Cart Description",
  },
  contact: {
    title: "Contact Us",
    desc: "Contact Us Description",
  },
  error: {
    title: "Error",
    desc: "Error Page Description",
  },
  projects: {
    title: "Projects",
    desc: "Projects Description",
  },
  projects1: {
    title: "Projects Card One Style",
    desc: "Projects Card One Style Description",
  },
  projects2: {
    title: "Projects Card Two Style",
    desc: "Projects Card Two Style Description",
  },
  projects3: {
    title: "Projects Card Three Style",
    desc: "Projects Card Three Style Description",
  },
  services: {
    title: "Services",
    desc: "Services Description",
  },
  team: {
    title: "Our Team",
    desc: "Our Team Description",
  },
  team1: {
    title: "Our Team Card One Style Team",
    desc: "Our Team Card One Style Team Description",
  },
  team2: {
    title: "Our Team Card Two Style Team",
    desc: "Our Team Card Two Style Team Description",
  },
  workspace: {
    title: "Workspace",
    desc: "Workspace Description",
  },
};

export default pageTitle;
