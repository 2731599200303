const education = [
  {
    date: "2012 - 2013",
    image: "/images/about/education/1.jpeg",
    department: "Master's degree in Computer Software Engineering",
    school: "The University of Manchester",
  },
  {
    date: "2009 - 2012",
    image: "/images/about/education/2.jpeg",
    department: "Bachelor's degree in Computer Software Engineering",
    school: "Harvard University",
  },
  {
    date: "2003 - 2007",
    image: "/images/about/education/3.jpeg",
    department: "Web Developer – Full Stack - Software Engineer ",
    school: "Istanbul University",
  },
];

export default education;
