const certifications = [
  {
    url: "#",
    isUrl: true,
    image: "/images/about/certifications/1.jpeg",
    company: "Google",
    title: "Search Ads 360 Certification Exam",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, magni. Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, magni.",
  },
  {
    url: "#",
    isUrl: true,
    image: "/images/about/certifications/2.jpeg",
    company: "Microsoft",
    title: "Azure Fundamentals Certification",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione, vero. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione, vero.",
  },
  {
    url: "#",
    isUrl: true,
    image: "/images/about/certifications/3.jpeg",
    company: "Amazon",
    title: "AWS Certified Solutions Architect",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, tempora. Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, tempora.",
  },
  {
    url: "#",
    isUrl: true,
    image: "/images/about/certifications/4.jpeg",
    company: "IBM",
    title: "IBM Cloud Architect Certification",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, reiciendis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, reiciendis.",
  },
];

export default certifications;
