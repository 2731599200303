const stories = [
  {
    url: "/images/profile/stories/1.avif",
    header: {
      heading: "Nature Scener",
      subheading: "Wonders of nature",
      profileImage: "/images/profile/stories/profile.webp",
    },
  },
  {
    url: "/images/profile/stories/2.avif",
    header: {
      heading: "City Scenery",
      subheading: "Modern city life",
      profileImage: "/images/profile/stories/profile.webp",
    },
  },
  {
    url: "/images/profile/stories/3.avif",
    header: {
      heading: "Art Gallery",
      subheading: "Modern city life",
      profileImage: "/images/profile/stories/profile.webp",
    },
  },{
    url: "/images/profile/stories/4.avif",
    header: {
      heading: "Art Gallery",
      subheading: "Modern city life",
      profileImage: "/images/profile/stories/profile.webp",
    },
  },
];

export default stories;
